import { v4 as uuidv4 } from 'uuid';
import urlstr from '../utilities/urlstr';
import { ContactVariants } from '../types/pim-contact-variants';
import { unformatArray } from '../redux/utils/actions.utils';

const API = {
  getProductContactLensPackRxs: async (id: string) => {
    const endpoint = urlstr({
      host: process.env.WARBY_API_DOMAIN,
      pathname: `/v1/catalog/contacts/${id}`,
    });

    const request = new window.Request(endpoint, {
      method: 'GET',
      headers: {
        'wp-system': 'Admin',
        'wp-country': 'Us',
        'wp-deviceId': uuidv4(),
      }, // Same response than membershipMFUSAdmin
    });

    const response = await fetch(request);

    if (response.ok) {
      const data = await response.json();

      if (!data) return null;
      const pimContactLensVariants = await new ContactVariants(
        data,
      ).toFormattedArray();
      return unformatArray(pimContactLensVariants);
    }

    return null;
  },
};

export default API;
